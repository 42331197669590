// BREAD CRUMBS
.breadcrumb {
    background-color:transparent;
    border-radius:0;
    padding:0.75rem 0;
    margin-bottom:0;

    .breadcrumb-item {
        color:#959494;

        &:before {
            font-weight:bold;
            color:#959494;
        }

        a {
            color:#959494;
            font-weight:bold;
            @include transition($transition-base);

            &.active {
                color:#959494;
            }

            @include hover-focus {
                text-decoration:none;
                color:$primary;
            }            
        }
    }
}