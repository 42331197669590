// import google roboto font
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i');

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css');

// import variables
@import '_variables';

// import bootrap
@import 'node_modules/bootstrap/scss/bootstrap';

@font-face {
    font-family: "FrankRuhlLibre";
    font-style: normal;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "FrankRuhlLibre-Black";
    font-style: black;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Black.ttf) format("truetype");
}

@font-face {
    font-family: "FrankRuhlLibre-Bold";
    font-style: Bold;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "FrankRuhlLibre-Light";
    font-style: light;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Light.ttf) format("truetype");
}

@font-face {
    font-family: "FrankRuhlLibre-Medium";
    font-style: medium;
    src: url(/wp-content/themes/sales-site/assets/fonts/FrankRuhlLibre/FrankRuhlLibre-Regular.ttf) format("truetype");
}

.frankRuhlLibre {
    font-family: "FrankRuhlLibre", Times, sans-serif;
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.mb-30, .my-30 {
    margin-bottom: 1.875rem !important;
}

.mb-2rem {
    margin-bottom:2rem;
}

h1, .h1 {
    font-family: "FrankRuhlLibre-Medium";
    font-size:50px;
    color:$secondary;

    @include media-breakpoint-down(md) {
        font-size:35px;
    }     
}

h2, .h2 {
    font-weight:400;
    margin-bottom:1rem;
}

.bg-gray {
    background-color:#F4F4F4;
}

.bg-overlay {
    background-color:$overlay-bg;
}

// import custom styling
@import 'custom/_custom';
@import 'sliders/_sliders';
@import 'pages/_pages';
@import 'containers/_container';

// Step Box List
.step-box-list {
    padding-left: 30px;
    padding-right: 30px;

    .row > [class*=col] {
        margin-bottom:30px;
    }

    .card {
        border-radius: 0; border:none;
        margin-bottom:30px;

        .card-img-top {
            border-radius: 0;
        }

        .card-body {
            margin-top:20px;

            .card-step {
                background: #F4F4F4;
                border-radius: 1em;
                -moz-border-radius: 1em;
                -webkit-border-radius: 1em;
                display: inline-block;
                font-weight: bold;
                line-height: 2em;
                text-align: center;
                width: 2em;
            }

            .card-title {
                font-family: 'FrankRuhlLibre-Medium';
                font-size:24px;
            }

            .card-text {
                font-size:16px;
            }
        }

        .card-footer {
            border-radius:none;
            border:none;
            background-color:transparent;
        }
    }
}

// BLOG LIST
.blog-list {
    padding-bottom:3rem;

    .figure {
        margin-bottom:30px;

        a.figure-link {
            display:block;
            overflow:hidden;
            background-color:$primary;

            img {
                display:block;
                margin-bottom:0px;
                transform: scale(1.12);
                @include transition($transition-base);

                @include hover-focus {
                    transform: scale(1);
                    opacity:0.5;
                } 
            }
        }

        .figure-caption {
            font-size:inherit;
            color:$gray-900;
            padding: 12px 20px;
        }

        .figure-name {
            border: 1px solid $secondary;
            padding: 12px 20px;
            font-family: 'FrankRuhlLibre-Medium';
            font-size:16px;
            color:$secondary;
        }

        .figure-btn {
            text-transform:uppercase;
            font-size:12px;
            font-weight:500;
            padding:0px;
            line-height:48px;
        }
    }
}

// BLOG POST
.blog-title {
    position:absolute;
    top:50%;
    left:90%;
    width:90%;
    transform: translateY(-50%);
    padding:40px 20px;
    line-height:1.0;
    font-family: 'FrankRuhlLibre-Medium';
    font-size:50px;
    background-color:#F4F4F4;
    opacity:0.84;

    @include media-breakpoint-down(md) {
        font-size:40px;
        right:0px;
        left:auto;
    }

    @include media-breakpoint-down(sm) {
        font-size:30px;
        left:0px;
        right:auto;
    }
}


.blog-date {
    font-family: 'FrankRuhlLibre-Medium';
    font-size:22px;
}

.blog-post-details {
    .social-icons {
        a {
            display:inline-block;
            margin-right:5px;
    
            img {
                width:35px;
                height:35px;
            }
        }
    }

    .author-name {
        font-size:15px;
        text-transform:uppercase;
    }
}

// TEAM LIST
.team-list {
    padding-bottom:3rem;

    .figure {
        margin-bottom:30px;

        a.figure-link {
            display:block;
            overflow:hidden;
            background-color:$primary;

            img {
                width: 100% !important;
                height: auto !important;
                display:block;
                margin-bottom:0px;
                transform: scale(1.12);
                @include transition($transition-base);

                @include hover-focus {
                    transform: scale(1);
                    opacity:0.5;
                } 
            }
        }

        .figure-caption {
            font-size:inherit;
            color:$gray-900;
            padding: 12px 5%;
        }

        .figure-name {
            border: 1px solid $secondary;
            padding: 12px 5%;
            font-family: 'FrankRuhlLibre-Medium';
            font-size:16px;
            color:$secondary;
        }

        .figure-btn {
            text-transform:uppercase;
            font-size:12px;
            font-weight:500;
            padding:0px;
            line-height:48px;
        }
    }
}

.bio-details {
    .social-icons {
        a {
            display:inline-block;
            margin-right:5px;
    
            img {
                width:35px;
                height:35px;
            }
        }
    }

    .author-name {
        padding-right:10%;

        h1, .h1 {
            word-break:break-word;
            line-height:1.0;
        }
    }
}

.bio-favorites {
    background-image: url(/wp-content/themes/sales-site/assets/images/2701Hubbard.jpg);
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-box-shadow: inset 0 0 0 1000px rgba(98,101,125,0.46);
    box-shadow: inset 0 0 0 1000px rgba(98,101,125,0.46);
    padding: 193px 40px;
    color:#fff;
    font-size:16px;

    @include media-breakpoint-down(sm) {
        padding: 100px 40px 50px 40px;
    }

    .favorite-container {
        padding-left:100px;
        padding-right:100px;

        @include media-breakpoint-down(lg) {
            padding-left:60px;
            padding-right:60px;
        }

        @include media-breakpoint-down(md) {
            padding-left:40px;
            padding-right:40px;
        }

        @include media-breakpoint-down(sm) {
            padding-left:20px;
            padding-right:20px;
            margin-bottom:50px;
        }
    }
}

// Neighborhood LIST
.neighborhood-list {
    padding-left: 30px;
    padding-right: 30px;

    .figure {
        margin-bottom:30px;

        a.figure-link {
            display:block;
            overflow:hidden;
            background-color:$primary;

            img {
                display:block;
                margin-bottom:0px;
                transform: scale(1.12);
                @include transition($transition-base);

                @include hover-focus {
                    transform: scale(1);
                    opacity:0.5;
                } 
            }
        }

        .caption {
            text-align:center;
            padding-top:15px;

            .name {
                font-family: 'FrankRuhlLibre-Medium';
                font-size:24px;
            }

            .description {
                font-size:16px;
            }

            .explore {
                margin-top:10px;
            }
        }
    }
}

.ihomefinder-fixed-menu {
    background-color:#fff;
    border-top: 1px solid #cdcdcd;
    -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.25);
    -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.25);
    box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.25);
}


.page-item{
    .page-link.current{
        color: white;
        background-color: $secondary;
        border-color: $secondary;
    }
}

.display-overflow{
    overflow: visible !important;
}