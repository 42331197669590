// PARALLAX
.parallax {
    background-position: center center;
    height:500px;

    @include media-breakpoint-down(sm) {
        height:calc(100vh - 50px);
    }

    .parallax-container {
        height:100%;
        color:#fff;
        font-size:18px;
        text-align:center;
        padding:15px;

        h1, .h1 {
            font-size:57px;
            margin-bottom:1rem;
            line-height:0.9;
        }

        h1, .h1, p, div, span {
            color:#fff;
        }

        .btn {
            font-size:18px;
        }

        &.parallax-overlay {
            background-color: rgba(55,55,56,0.64);
            background-blend-mode: overlay;
        }
    }

    &:after {
        content: ' ';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(55,55,56,0.64);
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-blend-mode: overlay;
    }
}