// TESTIMONIALS
.testimonial-container {
    padding-top:30px;
    padding-bottom:30px;

    .testimonial-box {
        border: 1px solid #938D76;
        padding: 42px 60px;
        margin-bottom:20px;

        .testimonial {
            overflow:hidden;
            height:15em;
        }

        .testimonial-bottom {
            margin-top: 16px;
            padding-top:6px;
            border-top: solid 1px #938D76;
            position:relative;
        
            &:before {
                position: absolute;
                left: -35px;
                content: ' ';
                width: 60px;
                height: 55px;
                background: url("/wp-content/themes/sales-site/assets/images/quote@3x.png") no-repeat;
                background-size: contain;
                display: inline-block;
                top: 20px;
                z-index: -1;
                opacity: .55;
            }

            .title {
                text-transform: uppercase;
                font-size:12px;
                color:#938D76;
                letter-spacing:1px;
            }

            .author {
                font-family: 'FrankRuhlLibre-Medium';
                font-size:16px;
            }

            .t-scroll {
                color:#000;
                font-size:18px;
                cursor:pointer;
            }
        }
    }
}