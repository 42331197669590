.testimonial-slider {
    
    .vc_grid-item{
        width: 100% !important;
    }
    
    .vc_grid.vc_grid-owl-theme .vc_grid-owl-nav {
        @media only screen and (min-width: 767px) {
            .vc_grid-owl-next, .vc_grid-owl-prev{
                font-size:100px;
            }
        }
        @media only screen and (max-width: 767px) {
            .vc_grid-owl-next, .vc_grid-owl-prev{
                font-size:50px;
            }
        }
    }
    .vc_grid.vc_grid-owl-theme .vc_grid-owl-nav {
        opacity: 100;
    }
    .vc_arrow-icon-arrow_10_left:before, .vc_arrow-icon-arrow_10_right:before {
        background-color: white;
    }

    &.slider-overlay{
        background: #373738;
        color: white !important;
        .owl-carousel {
            opacity: .8;
        }

        .caption{
            h1, a{
                color: white !important;
            }
        }
    }
}

.testimonial-details-slider{
    min-height: 640px !important;
    
	.vc_grid{
        text-align: center;
    }

    .vc_grid-item{
        max-width: 640px !important;
    }

    .owl-stage-outer, .vc_gitem-animated-block .vc_gitem-zone{
        min-height: 640px !important;
    }

    .vc_gitem-animated-block{
        width: 100% !important;
        min-width: 250px;
    }

    .vc_grid.vc_grid-owl-theme {
        @media only screen and (max-width: 767px) {
            .vc_gitem-animated-block{
                background-color: #373738 !important;
            }
            .vc_gitem-zone-a {
                opacity: 0.6 !important;
            }
        }

        .vc_grid-owl-nav {
            opacity: 100;

            @media only screen and (max-width: 450px) {
                display: none;
            }

            @media only screen and (min-width: 450px) {
                .vc_grid-owl-next, .vc_grid-owl-prev{
                    font-size:100px;
                }
            }

            @media only screen and (min-width: 767px) {
                position: absolute;
                max-width: 160px;
                min-width: 145px;
                width: 12vw;
                top: 50%;
                color: white;
            }

            @media only screen and (max-width: 767px) {
                margin-top: 100px;
                text-align: center;

                .vc_grid-owl-next, .vc_grid-owl-prev{
                    position: inherit;
                }
                .vc_arrow-icon-arrow_10_left:before, .vc_arrow-icon-arrow_10_right:before {
                    margin-right: 5px;
                    margin-left: 5px;
                }
            }
        }
    }

    .vc_arrow-icon-arrow_10_left:before, .vc_arrow-icon-arrow_10_right:before {
        background-color: black;
    }
}

.testimonial-details-slider-excerpt{
    position: absolute !important;
    width: 100% !important;
    top: 40% !important;
    left: 85%;
    opacity: 0.6;
    color: black;
    background-color:#F4F4F4;
    padding: 2vw;
    overflow: inherit !important;
    text-align: left !important;

    @media only screen and (min-width: 767px) {
        &:before {
            position: absolute;
            content: ' ';
            width: 75px;
            height: 75px;
            background: url("/wp-content/themes/sales-site/assets/images/quote@3x.png") no-repeat;
            background-size: contain;
            top: -50px;
            right: 50px;
            margin: -5px;
        }
    }

    p{
        font-size: 20px;
    }

    @media only screen and (max-width: 1400px) {
        top: 25% !important;
    }

    @media only screen and (max-width: 1200px) {
        top: 22% !important;

        p{
            font-size: large;
        }
    }
    
    @media only screen and (max-width: 767px) {
        top: 20% !important;
        left: 15%;
        width: 75% !important;
        background-color: black;
        color: white;

        p{
            font-size: large;
        }
    }

    @media only screen and (max-width: 400px) {
        top: 12% !important;
        left: 35px;
        width: 75% !important;
        background-color: black;
        color: white;

        p{
            font-size: medium;
        }
    }
}