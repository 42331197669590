.sitemap {
    ul {
        list-style-type:none;
        padding-left:0px;
        margin-bottom:0px;

        li {
            padding:10px 0px 10px 0px;
            border-bottom:1px solid $primary;

            a {
                padding:5px;
                font-weight:bold;
            }
        }

        &.children {
            padding-left:30px;
            li {
                border-bottom:none;
            }
        }
    }
}