
// SOCIAL ICONS CONTAINER
.social-container {
    padding:2rem 80px;
    color:#fff;
    background-color:#4D4F64;

    @include media-breakpoint-down(md) {
        padding:2rem 40px;
    }

    a {
        display:inline-block;
        margin-right:5px;

        &.wide {
            margin-right:4px;
        }

        img {
            width:22px;
            height:22px;
        }
    }

    .right-side {
        float:right;

        img {
            display:inline-block;
            margin-left:3px;
            width:22px;
            height:22px;
        }
    }
}