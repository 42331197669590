.blog-slider {
    
    .vc_grid-item{
        width: 100% !important;
    }

    .owl-stage-outer, .vc_gitem-zone{
        height: 640px !important;
    }
    
    .vc_grid.vc_grid-owl-theme .vc_grid-owl-nav {
        .vc_grid-owl-next, .vc_grid-owl-prev{
            font-size:100px;
            @include media-breakpoint-down(md) {
                font-size:50px;
            }
            @include media-breakpoint-down(xs) {
                display:none;
            }
        }
    }
    .vc_grid.vc_grid-owl-theme .vc_grid-owl-nav {
        opacity: 100;
    }
    .vc_arrow-icon-arrow_10_left:before, .vc_arrow-icon-arrow_10_right:before {
        background-color: white;
    }

    &.slider-overlay{
        background: #373738;
        color: white !important;
        .owl-carousel {
            opacity: .8;
        }
        h1, a {
            color: white !important;
        }
    }

    .caption {
        h1, a{
            font-size: 100px;
            @include media-breakpoint-down(xl) {
                font-size:40px;
            }
            @include media-breakpoint-down(lg) {
                font-size:38px;
            }
            @include media-breakpoint-down(md) {
                font-size:30px;
            }
            @include media-breakpoint-down(sm) {
                font-size:20px;
            }
        }
    }
}