// FIND YOUR HOME
.find-your-home {
    background-image:url("/wp-content/themes/sales-site/assets/images/process_bg.png");
    background-repeat:no-repeat;
    background-position: right top;
    background-size:auto 100%;

    @media (max-width: 1248px) {
        background-size:20% 100%;
    }

    @include media-breakpoint-down(lg) {
        background-size:15% 100%;
    }

    @include media-breakpoint-down(md) {
        background-image: none;
    }

    .find-your-home-row {
        position:relative;

        .find-your-home-item{
            height: 120px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }
        }

        h2, .h2 {    
            position: relative;
            top:0px;
            left:15px;
            margin-bottom: 90px;

            @include media-breakpoint-down(sm) {
                position:relative;
                margin-bottom: 3rem;
            }
        }

        .icon {
            width:70px;
            height:auto;
        }

        .description {
            padding:30px 15% 30px 0px;

            h6, .h6 {
                font-weight:400;
                text-transform: uppercase;
                font-size: 0.75rem;
            }

            p {
                font-size: 0.85rem;

                &:last-of-type {
                    margin-bottom:0px;
                }
            }
        }
    }
}

