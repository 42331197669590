
// SITE NAVIGATION
.navbar {
    z-index:20;
    padding: 0rem 3rem;

    @include media-breakpoint-down(md) {
        padding: 0.25rem 1.5rem;
    }

    .navbar-brand {
        img {
            max-width:125px;

            @include media-breakpoint-down(md) {
                max-width:100px;
            }
        }
    }

    .navbar-toggler {
        border-color: transparent;
        color:#fff;

        .navbar-toggler-icon {
            font-size:26px;
            @include transition(all .4s ease-in-out);
            background-image:url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='square' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }

        &[aria-expanded="true"] > .navbar-toggler-icon {
            transform: rotate(180deg);
            background-image:url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='square' stroke-miterlimit='10' d='M4 8l20 16M4 24L24 8'/%3E%3C/svg%3E");
        }

        &:focus {
            outline:none;
        }
    }

    .navbar-collapse {
        .navbar-spacer {
            display:none;
        }
    }

    .navbar-nav {

        @include media-breakpoint-down(md) {
            padding-top:0.75rem;
            padding-bottom:0.75rem;
        } 

        .nav-item {
            .nav-link {
                height:90px;
                line-height:90px;
                padding: 0rem 1rem;
                color:#fff;
                font-size:14px;
                @include transition($transition-base);

                &.disabled {
                    color:darken(#fff, 15%);
                }

                @include media-breakpoint-down(md) {
                    height:40px;
                    line-height:40px;
                    text-align:center;
                }

                @include hover-focus {
                    outline:none;
                }
            }

            &.dropdown {
                position:static;               
            }

            .dropdown-menu {
                margin:0;
                border-radius:0;
                border:none;
                background-color:$primary;
                color:#fff;
                padding: 0 3rem;
                text-align:right;

                @include media-breakpoint-down(md) {
                    text-align:center;
                    padding: 0;
                }

                .dropdown-item-container {
                    float:right;

                    @include media-breakpoint-down(md) {
                        float:none;
                    }

                    .dropdown-item {
                        float:left;
                        display:block;
                        clear:none;
                        width:auto;
                        color:#fff;
                        padding: 1rem 1rem;
                        @include transition($transition-base);

                        @include media-breakpoint-down(md) {
                            float:none;
                        }
    
                        @include hover-focus {
                            background-color:darken($primary, 5%);
                        }
    
                        &.active {
                            background-color:darken($primary, 5%);
                        }
                    }
                }
            }
        }
    }

    &.home {
        position:absolute;
        top:0px;
        width:100%;
        background-color:transparent !important;
        
        @include media-breakpoint-down(md) {
            padding: 0.26875rem 0rem 0.36875rem 0rem;
        }

        .navbar-brand {
            @include media-breakpoint-down(md) {
                position: relative;
                z-index: 500;
                margin-left:1.5rem;
            }
        }

        .navbar-toggler {
            @include media-breakpoint-down(md) {
                position: relative;
                z-index: 500;
                margin-right:1.5rem;
            }
        }

        .navbar-collapse {

            @include media-breakpoint-down(md) {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                padding: 0px 1.5rem 0rem 1.5rem;
                background-color:$secondary;
            }

            .navbar-spacer {
                display:block;
            }    
        }

        .navbar-nav {
            .nav-item {
                .nav-link {

                }

                &.dropdown {
                    position:relative;
                }

                .dropdown-menu {
                    width:auto !important;
                    text-align:left;
                    border:none;
                    background-color:transparent;
                    color:#fff;
                    padding: 0;
                    top:80%;

                    @include media-breakpoint-down(md) {
                        width:auto !important;
                        text-align:center;
                        border:1px solid $primary;
                        background-color:$primary;
                        color:#fff;
                        padding: 0;
                        top:100%;
                    }

                    .dropdown-item-container  {
                        float:none;

                        .dropdown-item {
                            float:none;
                            padding: 0.5rem 0.5rem;
    
                            &.active {
                                background-color:$primary;
                            }

                            @include hover-focus {
                                background-color:$primary;
                            }
    
                            @include media-breakpoint-down(md) {
                                padding: 1rem 1rem;

                                @include hover-focus {
                                    background-color:darken($primary, 5%);
                                }

                                &.active {
                                    background-color:darken($primary, 5%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .navbar {
        .navbar-nav {
            .nav-item {
                &.dropdown .dropdown-menu {
                    -webkit-transition: max-height 0.5s;
                    -moz-transition: max-height 0.5s;
                    -ms-transition: max-height 0.5s;
                    -o-transition: max-height 0.5s;
                    transition: max-height 0.5s;
                
                    opacity:0;
                    height:0;
                    max-height: 0;
                    display: block;
                    overflow: hidden;
                }
                
                &.dropdown:hover > .dropdown-menu {
                    opacity:1;
                    height:100%;
                    max-height: 53px;
                }
            }
        }

        &.home {
            .navbar-nav {
                .nav-item {
                    &.dropdown .dropdown-menu {
                        -webkit-transition: max-height 2.5s;
                        -moz-transition: max-height 2.5s;
                        -ms-transition: max-height 2.5s;
                        -o-transition: max-height 2.5s;
                        transition: max-height 2.5s;
                    
                        opacity:0;
                        height:0;
                        max-height: 0;
                        display: block;
                        overflow: hidden;
                    }
                    
                    &.dropdown:hover > .dropdown-menu {
                        opacity:1;
                        height:auto;
                        max-height: 800px;
                    }
                }
            }
        }
    }
}

.dropdown-item-container {
    .dropdown-item{
        @include media-breakpoint-down(md) {
            width:90% !important;
            padding-left: 10% !important;
        }
    }
}

.nav-link {
    position:relative;
    
    @include media-breakpoint-down(md) {
        width:90%;
        display:inline-block;
        padding-left: 10% !important;
    }
}

.dropdown-toggle::after {
    @include media-breakpoint-down(md) {
        display:none;
    }
}

.modal-backdrop {
    opacity:0.3;
}