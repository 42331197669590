
// FOOTER
.footer {
    padding:4rem 80px;
    background-color:$primary;
    color:$white;

    @include media-breakpoint-down(md) {
        padding:2rem 40px;
    }

    .footer-info {
        font-size:12px;
        width:80%;
        line-height:1.2;

        @include media-breakpoint-down(md) {
            width:100%;
        }

        .title {
            letter-spacing:5px;
            font-weight: 400;
        }
    }

    .footer-links {
        a {
            color:#fff;
            display:block;
            font-size:12px;
            @include transition($transition-base);

            @include hover-focus {
                text-decoration:none;
                color:darken(#fff, 15%);
            }  
        }

        .menu-footer-menu-container {
            ul.menu { 
                display:inline-block;
                margin-bottom:0px;
                margin-left:0px;
                padding-left:0px;
    
                li {
                    list-style-type: none;
                    line-height: 2.3;
                    font-size:12px;
                }
            }
        }
    }

    .contact-info {
        .address {
            font-size:12px;
            line-height: 16px;
        }

        .contact-links {
            font-size:12px;
            line-height: 16px;

            a {
                color:#fff;
                font-size:12px;
                @include transition($transition-base);

                @include hover-focus {
                    text-decoration:none;
                    color:darken(#fff, 15%);
                } 
            }
        }
    }
}