.slider {
    img {
        width: 100% !important;
        height: auto !important;
    }
}

// Image carousel next/prev arrows
.vc_images_carousel .vc_carousel-control{
    top: 40% !important;

    .icon-prev {
        font-size: 12vw !important;
        color: white !important;
        right: 0 !important;
    }
    .icon-next{
        font-size: 12vw !important;
        color: white !important;
        left: 0 !important;
    }

}

@import "_home-slider";
@import "_blog-slider";
@import "_testimonial-slider";