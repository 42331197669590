// Home Slider
.home-slider {

    .vc_active{
        background-color: white !important;
    }

    &.slider-overlay{
        .wpb_images_carousel{
            background-color: $overlay-bg;
            .wpb_wrapper{
                opacity: 0.6;
            }
        }
    }

    .home-slider-content{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        z-index: 10;
        color:#fff !important;
        background-color:transparent;
        text-align:center;

        h1, .h1 {
            color:#fff !important;
            font-size:57px;
            @include media-breakpoint-down(sm) {
                font-size:25px !important;
            }
        }

        .sub-heading {
            font-size:18px;
            @include media-breakpoint-down(sm) {
                font-size:12px !important;
            }
        }
    }
    
    .phone-number{
        position:absolute; 
        bottom:2.5rem; 
        left:3rem; 
        z-index: 10; 
        color:#fff; 
        background-color:transparent;
    }
}