.padding {
    padding:4.5rem 80px;

    @include media-breakpoint-down(md) {
        padding:4rem 40px;
    }
}

.padding-row {
    padding:4.5rem 100px;

    @include media-breakpoint-down(md) {
        padding:4rem 40px;
    }

    @include media-breakpoint-down(sm) {
        padding:3rem 15px;
    }
}

.padding-xs {
    padding:3rem 0px;

    @include media-breakpoint-down(md) {
        padding:1rem 20px;
    }

    @include media-breakpoint-down(sm) {
        padding:0.25rem 15px;
    }
}

.padding-sm {
    padding:1.3rem 80px;

    @include media-breakpoint-down(md) {
        padding:1.3rem 40px;
    }

    @include media-breakpoint-down(sm) {
        padding:0.5rem 30px;
    }
}

.padding-md {
    padding:4.5rem 120px;

    @include media-breakpoint-down(md) {
        padding:4rem 60px;
    }

    @include media-breakpoint-down(sm) {
        padding:3rem 30px;
    }
}

.padding-blog-header {
    padding:3rem 80px;

    @include media-breakpoint-down(md) {
        padding:3rem 40px;
    }
}

.padding-testimonial-header {
    padding:3rem 80px;

    @include media-breakpoint-down(md) {
        padding:3rem 40px;
    }
}

.padding-none{
    padding: 0;
}

.padding-sm-ihome {
    padding:1.3rem 120px;

    @include media-breakpoint-down(md) {
        padding:1.3rem 60px;
    }

    @include media-breakpoint-down(sm) {
        padding:1.3rem 30px;
    }
}